import {
  Layout,
  RegistrationCheckForm
} from 'Components';

import HeroBg from './bg.jpg';;

const heroStyle = {
  // backgroundImage: `url(${HeroBg})`,
  // backgroundSize: 'cover',
  // backgroundPosition: 'center center'
};

const HomePage = () => {
  return (
    <Layout
      header={<h2>Header</h2>}
      content={
        <section className="hero has-text-centered is-medium is-info" style={heroStyle}>
          <div className="hero-body">
            <p className="title">Large hero</p>
            <p className="subtitle">Large subtitle</p>
            <RegistrationCheckForm />
          </div>
        </section>
      }
    />
  );
}

export default HomePage;
