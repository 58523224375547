import IconLogo from './icon.png';

const Layout = (props) => {
  const { header, content, loading } = props;

  return (
    <>
      <div className="navbar">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={IconLogo} alt="Logo" />
          </a>
        </div>
        <div className="navbar-menu">
          <a href="/" className="navbar-item">
            Documentation
          </a>
        </div>
      </div>
      <div className="container">
        <div className="section">
          {loading
            ? <progress className="progress is-small is-primary" max="100">15%</progress>
            : content
          }
        </div>
      </div>
    </>
  );
}

export default Layout;
