import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicleDetails } from 'Utils/api/getVehicleDetails';

import {
  Layout
} from 'Components';

const ReportPage = () => {
  const { reg } = useParams();
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDetails = () => {
    getVehicleDetails(reg).then(response => {
      setIsLoading(false);
      setVehicleDetails(response);
      console.log(response);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <Layout
        content={
          isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="box">
              <ul>
                <li>Registration: {vehicleDetails.registration_number}</li>
                <li>Make: {vehicleDetails.data.make}</li>
                <li>Colour: {vehicleDetails.data.colour}</li>
              </ul>
            </div>
          )
        }
      />
    </>
  );
}

export default ReportPage;
