import { API_BASE_URL } from 'Utils/constants';
import { request } from 'Utils/request';
import { formatRegistration } from 'Utils/formatRegistration';

export function getVehicleDetails(registrationNumber) {
  return request({
    url: API_BASE_URL,
    method: "POST",
    body: JSON.stringify({ registrationNumber: formatRegistration(registrationNumber) })
  });
}
