import {
  HomePage,
  ReportPage
} from 'Pages';

const routes = {
  home: {
    path: '/',
    component: HomePage,
  },
  report: {
    path: '/report/:reg',
    component: ReportPage,
  }
};

export default routes;
