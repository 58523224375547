import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from 'Routes';
import 'bulma/css/versions/bulma-no-dark-mode.min.css';

const App = () => {
  return (
    <Router>
      <Routes>
        {Object.values(routes).map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
