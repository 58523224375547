import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RegistrationCheckForm = () => {
  const navigate = useNavigate();
  const [reg, setReg] = useState('');

  const handleClick = () => {
    navigate(`/report/${reg}`);
  }

  return (
    <div className="box">
      <div className="field">
        <input
          className="input is-large"
          type="text"
          placeholder="Enter your UK registration plate"
          value={reg}
          onChange={(e) => setReg(e.target.value)}
        />
      </div>
      <div className="field">
        <button className="button is-info is-light is-medium" onClick={handleClick}>Go to Report</button>
      </div>
    </div>
  );
}

export default RegistrationCheckForm;
